import {
  CognitoUser,
  CognitoUserPool,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";

let user;
const poolData = {
  UserPoolId: "us-east-2_HKptx6yDa",
  ClientId: "2h6g08ch8ib4ed6h4qjfq11a1n",
};
const userPool = new CognitoUserPool(poolData);

const handleLogin = (username, password) => {
  user = new CognitoUser({
    Username: username,
    Pool: userPool,
  });

  const authDetails = new AuthenticationDetails({
    Username: username,
    Password: password,
  });

  return new Promise((resolve, reject) => {
    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        console.log("Authentication successful");
        resolve(true);
      },
      onFailure: (err) => {
        console.error("Authentication failed", err);
        reject("Authentication failed");
      },
    });
  });
};

const getJwtToken = () => {
  if (user) {
    const jwtToken = user.signInUserSession.accessToken.jwtToken;
    return jwtToken;
  }
};

const handleLogout = () => {
  if (user instanceof CognitoUser) {
    user.signOut();
    console.log("Sign-out successful");
  } else {
    console.log("Nothing to sign out");
  }
};

export { handleLogin, handleLogout, getJwtToken };
