import React from "react";
import { FaSearch } from "react-icons/fa";

function SearchBar({ arabic, searchQuery, setSearchQuery }) {
  const handleSearch = (event) => {
    if (event.target.value) {
      setSearchQuery(event.target.value);
    } else {
      setSearchQuery("");
    }
  };

  return (
    <div className="max-w-md mb-4 ml-auto">
      <div className="flex items-center">
        <input
          id="search"
          type="text"
          placeholder={arabic ? "...يبحث" : "Search..."}
          value={searchQuery}
          onChange={handleSearch}
          className={`border border-gray-400 p-2 w-full rounded-none rounded-l-md h-10  ${
            arabic ? "text-right" : ""
          }`}
        />
        <button className="bg-custom-main-light text-white p-3 rounded-r-md hover:bg-custom-main-dark">
          <FaSearch />
        </button>
      </div>
    </div>
  );
}

export default SearchBar;
