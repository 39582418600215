import React from "react";

function Footer() {
  // const currentYear = new Date().getFullYear();
  return (
    <footer className="bg-custom-main-light text-white py-2 text-center mt-10">
      <div className="flex-grow"></div>
      <div className="py-3">
        <p>Sheikh Abu Mujaahid Fareed Abdullah Official Site</p>
      </div>
    </footer>
  );
}

export default Footer;
