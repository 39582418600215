import React from "react";
import { BookItem } from "../Components";

function BooksPage({ arabic, booksList }) {
  return (
    <div className="text-right mx-1 md:mx-7">
      <h1 className="text-black font-bold mb-5">
        {arabic ? "الكتب" : "Books"}
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
        {booksList.map((book, index) => (
          <BookItem key={index} arabic={arabic} book={book} />
        ))}
      </div>
    </div>
  );
}

export default BooksPage;
