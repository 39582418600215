import React, { useState, useRef, useEffect } from "react";
import { getJwtToken } from "./authService";

function AddMediaForm({ logout }) {
  const [mediaType, setMediaType] = useState("audioLectures"); // Default to 'audioLectures'
  const [englishTitle, setEnglishTitle] = useState("");
  const [arabicTitle, setArabicTitle] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [file, setFile] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(""); // To track upload status
  const [showUploadAnother, setShowUploadAnother] = useState(false); // Show upload another upon successful upload

  const fileInputRef = useRef(null); // Reset uploaded filename
  const [loadingDots, setLoadingDots] = useState(0);

  const [topics, setTopics] = useState([]); // State for the list of topics
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [newEnglishTopic, setNewEnglishTopic] = useState("");
  const [newArabicTopic, setNewArabicTopic] = useState("");
  const [isAddingNewTopic, setIsAddingNewTopic] = useState(false); // State for toggling new topic input

  const handleMediaTypeChange = (event) => {
    resetForm();
    setMediaType(event.target.value);
  };

  useEffect(() => {
    fetchTopics();
  }, []);

  const fetchTopics = () => {
    fetch(
      "https://jt8bip5axg.execute-api.us-east-2.amazonaws.com/default/fetchAndAddTopics",
      {
        method: "GET",
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json(); // Only parse the response if it's successful
        } else {
          throw new Error("Failed to fetch topics");
        }
      })
      .then((data) => {
        setTopics(data);
      })
      .catch((error) => {
        console.error("Error fetching topics!", error);
      });
  };

  const addTopic = () => {
    if (newEnglishTopic && newArabicTopic) {
      const requestBody = {
        englishTopic: newEnglishTopic,
        arabicTopic: newArabicTopic,
      };

      const jwtToken = getJwtToken(); // Get the JWT token after user login
      const headers = {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      };

      fetch(
        " https://jt8bip5axg.execute-api.us-east-2.amazonaws.com/default/fetchAndAddTopics",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(requestBody),
        }
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Failed to add topic");
          }
        })
        .then((data) => {
          // Do something with the response data if needed
          console.log("Topic added successfully:", data);
          setTopics((prevTopics) => [...prevTopics, requestBody]);
          setIsAddingNewTopic(false); // Hide the new topic input
        })
        .catch((error) => {
          console.error("Error adding topic:", error);
        });
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setButtonDisabled(true); // Disable the upload button

    const requestBody = {
      mediaType,
      englishTitle,
      arabicTitle,
      description,
      date,
      englishTopic: selectedTopic ? selectedTopic.englishTopic : "",
      arabicTopic: selectedTopic ? selectedTopic.arabicTopic : "",
    };

    const formDataToUpload = new FormData();
    formDataToUpload.append("file", file);

    const jwtToken = getJwtToken(); // Get the JWT token after user login
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    };

    // Make a POST request to API endpoint for media upload
    fetch(
      "https://69rm9zfhcb.execute-api.us-east-2.amazonaws.com/default/getPresignedURL",
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(requestBody),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        const preSignedUrl = data.uploadURL;
        // Use the pre-signed URL to upload the media file to S3
        uploadMediaToS3(preSignedUrl, file);
      })
      .catch((error) => {
        console.error("Error uploading media:", error);
        setUploadStatus("Failed");
        setButtonDisabled(true);
      });
  };

  async function uploadMediaToS3(preSignedUrl, file) {
    try {
      const response = await fetch(preSignedUrl, {
        method: "PUT", // Use the PUT method for S3 uploads
        body: file,
        headers: {
          "Content-Type": file.type, // Set the content type based on the file type
        },
      });

      if (response.ok) {
        console.log("Media successfully uploaded to S3");
        setUploadStatus("Success");
        setButtonDisabled(true);
        setShowUploadAnother(true); // Show the "Upload Another" button
      } else {
        console.error("Failed to upload media to S3");
        setUploadStatus("Failed");
        setButtonDisabled(true);
      }
    } catch (error) {
      console.error("Error uploading media to S3:", error);
      setUploadStatus("Failed");
      setButtonDisabled(true);
    }
  }

  const resetForm = () => {
    setMediaType("audioLectures");
    setEnglishTitle("");
    setArabicTitle("");
    setDescription("");
    setDate("");
    setNewEnglishTopic("");
    setNewArabicTopic("");
    setSelectedTopic(null);
    setIsAddingNewTopic(false);
    setFile(null);
    setButtonDisabled(false);
    setUploadStatus("");
    setShowUploadAnother(false);
    resetFileInput();
  };

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the file input value
    }
    setFile(null);
  };

  useEffect(() => {
    // Toggle loading dots animation every 500ms when uploading is in progress
    let interval;
    if (
      buttonDisabled &&
      uploadStatus !== "Success" &&
      uploadStatus !== "Failed"
    ) {
      interval = setInterval(() => {
        setLoadingDots((prevDots) => (prevDots === 2 ? 0 : prevDots + 1));
      }, 500);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [buttonDisabled, uploadStatus]);

  return (
    <div className="flex justify-center items-center">
      <div className="p-8 w-full max-w-md bg-white rounded-lg shadow-lg">
        <h1 className="text-2xl font-semibold mb-4">Upload Media</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="mediaType"
            >
              Media Type
            </label>
            <select
              className="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="mediaType"
              value={mediaType}
              onChange={handleMediaTypeChange}
              required
            >
              <option value="audioLectures">Audio Lectures</option>
              <option value="khutbahs">Khutbahs</option>
              <option value="books">Books</option>
            </select>
          </div>

          {mediaType === "audioLectures" && (
            <div className="mb-4">
              <div className="flex items-center">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="topic"
                >
                  Topic
                </label>
                {!isAddingNewTopic && (
                  <button
                    type="button"
                    onClick={() => setIsAddingNewTopic(true)}
                    className="text-custom-main-light hover:text-custom-main-dark text-xs ml-2 mb-2 hover:underline focus:underline"
                  >
                    Add New Topic
                  </button>
                )}
              </div>

              <select
                className="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="topic"
                value={selectedTopic ? JSON.stringify(selectedTopic) : ""}
                onChange={(e) => {
                  setSelectedTopic(
                    e.target.value ? JSON.parse(e.target.value) : ""
                  );
                }}
                required
              >
                <option value={""}>Select a topic</option>
                {topics.map((topic, index) => (
                  <option key={index} value={JSON.stringify(topic)}>
                    {topic.englishTopic + " | " + topic.arabicTopic}
                  </option>
                ))}
              </select>
            </div>
          )}
          {isAddingNewTopic && (
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="newTopicEnglish"
              >
                New Topic (Optional)
              </label>
              <input
                className="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2"
                id="newTopicEnglish"
                type="text"
                placeholder="English Topic"
                value={newEnglishTopic}
                onChange={(e) => setNewEnglishTopic(e.target.value)}
              />
              <label htmlFor="newTopicArabic"></label>
              <input
                className="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2"
                id="newTopicArabic"
                type="text"
                placeholder="Arabic Topic"
                value={newArabicTopic}
                onChange={(e) => setNewArabicTopic(e.target.value)}
              />
              <button
                type="button"
                onClick={addTopic}
                className="bg-custom-main-light hover:bg-custom-main-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Add Topic
              </button>
            </div>
          )}

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="englishTitle"
            >
              English Title
            </label>
            <input
              className="w-full md:w-96 border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-black focus:shadow-outline"
              id="englishTitle"
              type="text"
              placeholder="English Title"
              value={englishTitle}
              onChange={(e) => setEnglishTitle(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="arabicTitle"
            >
              Arabic Title
            </label>
            <input
              className="w-full md:w-96 border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-black focus:shadow-outline"
              id="arabicTitle"
              type="text"
              placeholder="Arabic Title"
              value={arabicTitle}
              onChange={(e) => setArabicTitle(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="date"
            >
              Date (Optional)
            </label>
            <input
              className="w-full md:w-96 border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-black focus:shadow-outline"
              id="date"
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
          {mediaType === "books" && (
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="description"
              >
                Description (Optional)
              </label>
              <textarea
                className="w-full md:w-96 border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-black focus:shadow-outline"
                id="description"
                rows="4"
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          )}

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="file"
            >
              Upload {mediaType === "books" ? "PDF" : "Audio"}
            </label>
            <input
              className="w-full"
              id="file"
              type="file"
              accept={mediaType === "books" ? ".pdf" : ".mp3, .wav, .mp4, m4a"} // Specify allowed file types
              onChange={handleFileChange}
              ref={fileInputRef}
              required
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-custom-main-light hover:bg-custom-main-dark focus:bg-custom-main-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
              disabled={buttonDisabled} // Disable the button based on state
            >
              {buttonDisabled
                ? uploadStatus === "Success"
                  ? "Uploaded"
                  : uploadStatus === "Failed"
                  ? "Upload Failed"
                  : `Uploading${".".repeat(loadingDots + 1)}` // Display loading dots
                : "Upload"}
            </button>
            {showUploadAnother && (
              <button
                className="bg-custom-main-light hover:bg-custom-main-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-2 text-xs md:text-base"
                type="button"
                onClick={resetForm}
              >
                Upload Another
              </button>
            )}
            <p
              className="text-custom-main-light hover:text-custom-main-dark hover:underline focus:underline cursor-pointer"
              onClick={logout}
            >
              Logout
            </p>
          </div>
          {uploadStatus && (
            <p
              className={
                uploadStatus === "Failed" ? "text-red-600 mt-1" : "mt-1"
              }
            >
              {uploadStatus === "Failed"
                ? "Error uploading. Please refresh the page and try again."
                : "Upload Successful."}
            </p>
          )}
        </form>
      </div>
    </div>
  );
}

export default AddMediaForm;
