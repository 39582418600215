import React, { useState } from "react";
import { handleLogin } from "./authService";

function LoginForm({ login, loginError }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLoginInAttempt = async () => {
    try {
      const success = await handleLogin(username, password);
      if (success) {
        login(true);
      } else {
        login(false);
      }
    } catch (error) {
      console.error("Login error:", error);
      login(false);
    }
  };

  return (
    <div className="flex justify-center items-center">
      <div className="p-8 w-full max-w-md bg-white rounded-lg shadow-lg">
        <h1 className="text-2xl font-semibold mb-4">Login</h1>
        <form>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="username"
            >
              Username
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-black focus:shadow-outline"
              id="username"
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              autoComplete="on"
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-black focus:shadow-outline"
              id="password"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {loginError ? (
              <p className="text-red-500 text-xs italic">
                Incorrect username or password.
              </p>
            ) : (
              <p className="text-red-500 text-xs italic">Enter password.</p>
            )}
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-custom-main-light hover:bg-custom-main-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={handleLoginInAttempt}
            >
              Sign In
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginForm;
