import React from "react";

function Pagination({ currentPage, totalItems, itemsPerPage, goToPage }) {
  return (
    <div className="mt-5">
      {totalItems > itemsPerPage && (
        <div className="flex justify-center space-x-4">
          {currentPage > 1 && (
            <button
              onClick={() => goToPage(currentPage - 1)}
              className="bg-custom-main-light text-white px-4 py-2 rounded hover:bg-custom-main-dark"
            >
              Previous
            </button>
          )}
          <div className="flex justify-center items-center">
            <span className="text-gray-500">
              Page {currentPage} of {Math.ceil(totalItems / itemsPerPage)}
            </span>
          </div>
          {currentPage < Math.ceil(totalItems / itemsPerPage) && (
            <button
              onClick={() => goToPage(currentPage + 1)}
              className="bg-custom-main-light text-white px-4 py-2 rounded hover:bg-custom-main-dark"
            >
              Next
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default Pagination;
