import React, { useState } from "react";
import { LoginForm, AddMediaForm } from "../Components";
import { handleLogout } from "../Components/authService.js";

function AddMediaPage() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const login = (loggedIn) => {
    if (loggedIn) {
      setIsLoggedIn(true);
      setLoginError(false);
    } else {
      setIsLoggedIn(false);
      setLoginError(true);
    }
  };

  const logout = () => {
    handleLogout();
    setIsLoggedIn(false);
  };

  return (
    <>
      {isLoggedIn ? (
        <AddMediaForm logout={logout} />
      ) : (
        <LoginForm login={login} loginError={loginError} />
      )}
    </>
  );
}

export default AddMediaPage;
