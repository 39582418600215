import React from "react";

export default function BookItem({ arabic, book }) {
  return (
    <div className="bg-white border rounded-lg overflow-hidden shadow-md">
      <div className="p-5 flex flex-col h-full">
        <div className="flex justify-between mb-3">
          <p className="text-gray-500 text-xs md:text-base">{book.date}</p>
          <h2 className="font-semibold text-sm md:text-base">
            {arabic ? book.arabicTitle : book.englishTitle}
          </h2>
        </div>
        <p className="text-gray-700 mb-2 flex-grow">{book.description}</p>
        <div className="mt-auto">
          <a
            href={`https://d1fx0qbs85f2yg.cloudfront.net/${book.mediaUrl}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-custom-main-light hover:underline"
          >
            {arabic ? "اعرض الكتاب" : "View Book"}
          </a>
        </div>
      </div>
    </div>
  );
}
