import React, { useState, useEffect, useRef } from "react";
import { navLinks } from "../Constants";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

function Navbar({ arabic, toggleArabic }) {
  const [open, setOpen] = useState(false);
  const navRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (navRef.current && !navRef.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <nav ref={navRef} className="shadow-md w-full fixed mb-10 z-10">
      <div className="items-center justify-between bg-custom-main-dark py-2 md:py-0 md:px-0 px-7 ">
        <div className="flex items-center justify-between">
          <div
            className="font-bold text-white text-1xl md:ml-10 cursor-pointer"
            onClick={toggleArabic}
          >
            {arabic ? <p>EN</p> : <p className=" text-xs">العربية</p>}
          </div>
          <div className=" font-bold text-white text-right text-[10px] md:text-xl md:mr-10">
            {arabic ? (
              <p className="">
                الموقع الرسمي للشيخ أبي مجاهد فريد عبد الله رحمه الله
              </p>
            ) : (
              <p className="text-center">Sheikh Abu Mujaahid Fareed Abdullah</p>
            )}
          </div>
          <div
            onClick={() => setOpen(!open)}
            className="flex items-center cursor-pointer md:hidden"
          >
            {open ? (
              <AiOutlineClose size={28} style={{ color: "#ffff" }} />
            ) : (
              <AiOutlineMenu size={28} style={{ color: "#ffff" }} />
            )}
          </div>
        </div>

        <ul
          className={`bg-custom-main-light text-white md:flex md:justify-between md:pb-0 pb-11 absolute md:static md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl- transition-all duration-500 ease-in ${
            open ? "top-10 " : "top-[-480px]"
          }`}
        >
          {navLinks.map((link) => (
            <CustomLink
              key={link.id}
              to={link.id}
              icon={link.icon}
              onClick={() => setOpen(!open)}
            >
              {`${arabic ? link.titleAR : link.title}`}
            </CustomLink>
          ))}
        </ul>
      </div>
    </nav>
  );
}
function CustomLink({ to, children, className, icon, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li
      className={`${"md:ml-8 md:text-sm text-xl md:my-0 my-7 text-right mr-9 hover:text-gray-400 duration-500"} ${
        isActive ? "underline text-gray-400 " : ""
      }`}
    >
      <Link to={to} {...props} className="inline-flex items-center">
        {children}
        {icon && <span className="ml-2 md:ml-1">{icon}</span>}
      </Link>
    </li>
  );
}

export default Navbar;
