import React from "react";

function BioPage({ arabic }) {
  return (
    <>
      <h1 className="text-black font-bold text-right mr-7">
        {arabic ? "ترجمة الشيخ" : "Biography"}
      </h1>
      <div className="flex flex-col items-center">
        <h2 className="text-lg text-center my-10">بسم الله الرحمن الرحيم</h2>
        {arabic ? (
          <div
            className="max-w-lg mx-7 flex flex-col items-center align-center"
            dir="rtl"
            lang="ar"
          >
            <p className="text-lg mb-10">
              ترجمة الشيخ أبي مجاهد فريد عبد الله
            </p>
            هو الشيخ العلامة المحدث الفقيه فريد يوسف عبد الله، أبو مجاهد
            البهامي، نزيل (لوس أنجلس).
            <br />
            <br />
            ولد في (ناسو) عاصمة جزر البهاما في العشرين من شهر الله المحرم سنة
            1378، ونشأ على دين والديه.
            <br />
            <br />
            أسلم عندما كان في مرحلة الدراسة الثانوية وهو في الرابعة عشرة أو
            الخامسة عشرة من عمره، وأقبل على تعلم دين الإسلام، ثم غير اسمه الذي
            سماه به والداه بعد إسلامه بسنة لأنه لم يعد يريد أن يحمل أسماء أولئك
            الذين اضطهدوا أجداده.
            <br />
            <br />
            وكان في تلك المرحلة مدفوعا بحماسة الشباب، فكان يدعو أهل بلده إلى
            الإسلام في الأماكن العامة والخاصة ويصلي حيث أدركته الصلاة، ولا تأخذه
            في الله لومة لائم. وحاولت والدته ثنيه عن ذلك واستعانت بأقاربه وبأهل
            الكنيسة، ولكنه كان ثابتا على الحق.
            <br />
            <br />
            ثم بدأ رحلته في طلب العلم، فسافر إلى غيانا في أمريكا الجنوبية، ومكث
            فيها سنة تعلم فيها اللغة العربية وبعض العلوم الإسلامية الأخرى. ورحل
            إلى الهند وباكستان وبنغلادش.
            <br />
            <br />
            ثم عاد إلى بلده، وانطلق منها إلى المملكة العربية السعودية حيث درس في
            جامعة الإمام محمد بن سعود الإسلامية بالرياض. وهناك حفظ كتاب الله
            وتأصل في مختلف العلوم الإسلامية ولقي العلماء والمشايخ وعلى رأسهم
            الشيخ عبد العزيز بن باز والشيخ محمد بن صالح العثيمين.
            <br />
            <br />
            وأقبل على كتب الشيخ محمد ناصر الدين الألباني، فصارت له عناية بعلم
            الحديث حتى برع فيه وصارت له معرفة تامة بفنونه مع حفظه لمتونه، فكان
            يسرد الحديث من حفظه لا يكاد يخطئ في حرف منه، وكان ماهرا في استحضار
            الأحاديث والاستشهاد بها واستخراج الفوائد منها.
            <br />
            <br />
            وكان حفظه لكتاب الله متقنا، فقد كان يسهل عليه استحضار الآيات عند
            الاستشهاد بها، وإذا أخطأ القارئ أو أرتج عليه كان أول من يرده.
            <br />
            <br />
            وكات عقيدته سلفية محضة كان يحفظ آثار الصحابة ويستحضر أقوال أهل العلم
            المتقدمين والمتأخرين ويلجأ إليها في فهم النصوص والعمل بها.
            <br />
            <br />
            وبرع في الفقه وخاصة فقه النكاح والطلاق وما يتعلق بهما وفقه البيوع
            والمعاملات المالية.
            <br />
            <br />
            وبعد تخرجه من الجامعة، ذهب إلى (لوس أنجلس) في سنة 1412، فاستقر بها،
            وأصبح إماما لمسجد المؤمن ومدرسا في مدرسة المدينة، وبدأ في الدعوة إلى
            الله تعالى وكان منهجه هو اتباع الكتاب والسنة بفهم سلف الأمة.
            <br />
            <br />
            وكان يسافر في الدعوة إلى المناطق البعيدة والقريبة، فسافر إلى
            (نيويورك) و(فيلادلفيا) و(نيو جيرزي) و(كانزاس سيتي) و(سالينا) و(ريفر
            سايد) و(فرزنو) و(سان دييغو) وغيرها. وكان لدعوته أثر كبير في كل
            المناطق التي ذهب إليها، بل وفي أخرى لم يذهب إليها، فقد وصلت دعوته
            إلى أفريقيا وأوروبا، وكان له أثر كبير على كل من لقيه وتعلم منه.
            <br />
            <br />
            وفي سنة 1421 التقى بالشيخ مقبل بن هادي الوادعي عندما جاء إلى
            (كاليفورنيا) للعلاج، ففرح به وقد كانت تراوده نفسه بالرحلة إليه،
            فأحبه الشيخ مقبل وقدمه وزكاه وشهد له بالعلم والفضل.
            <br />
            <br />
            كان الشيخ -رحمه الله- محبا للسنة متمسكا بها حريصا على اتباعها في كل
            صغيرة وكبيرة، وكأنها قد اختلطت بشحمه ودمه، فقد كان يرى أثر ذلك عليه
            في تواضعه ولين كلامه وحسن خلقه وإحسانه إلى الناس.
            <br />
            <br />
            وكان صاحب عبادة، حسن الصلاة، يواظب على صلاة الضحى وصلاة الليل في
            السفر. وكان إذا أقيمت الصلاة مشى بين الناس يسوي الصفوف فإذا استقامت
            تقدم فكبر. وكان إذا صلى العيد خطب ثم أتى النساء فوعظهن.
            <br />
            <br />
            وكان يورد الدروس بتؤدة، وكان صوته نديا لينا، وكان فصيح اللسان
            باللغتين العربية والإنجليزية. وكانت دروسه ومحاضراته تمر سريعا حتى
            يتمنى الحاضرون ألا تنتهي من حلاوة الكلام وبراعة الإلقاء، وكأنه كان
            يأخذ الحاضرين معه في نزهة. إذا جاء ذكر الجنة أشرق وجهه وتبسم وهز
            رأسه وكأنه يراها.
            <br />
            <br />
            كان حسن العشرة في بيته وخارجه، طيب الكلام لا يمل من مجلسه، يكلم
            الناس ويسامرهم ويؤاكلهم ويمازحهم. إذا دخل الغريب إلى مجلسه لم يعرف
            أنه إمام القوم وشيخهم ومعلمهم من شدة تواضعه. وكان لا يحب أن يقال له:
            الشيخ، وكان يغضب من ذلك وينظر نظرة حازمة إلى من خاطبه بها -ويا لها
            من نظرة- ويقول له: "أبو مجاهد".
            <br />
            <br />
            أحبه الصغار والكبار، فقد كان موقرا للكبير رحيما بالصغير، يسلم على
            الصبيان ويلاعبهم. وإذا صافح لا يكون أول من يسحب يده. وكان قليل
            الشكوى، لا يكاد يشكو إلى أحد، ويعرف أصحابه أن به بأسا عندما يقول:
            "الحمد لله على كل حال". ولم يكن يسأل أحدا شيئا من أمر الدنيا.
            <br />
            <br />
            كل ذلك كان سهلا يسيرا عليه، لا تكلف فيه، لأنه كان متبعا حقا ولم يكن
            متصنعا، يتعلم منه من جالسه ما لا يجده في كتاب.
            <br />
            <br />
            وله تصانيف حسنة ما بين تأليف وشرح، يحسن جمع الأدلة وترتيبها ونقل
            كلام العلماء عليها، وله تعليقات لطيفة. وله حواش نفيسة على الكتب التي
            اقتناها.
            <br />
            <br />
            في سنة 1439 عاد إلى موطنه، وبقي هناك يدرس ويدعو إلى الله -تعالى- إلى
            أن توفي -رحمه الله- عصر يوم الثلاثاء الحادي والعشرين من شهر الله
            المحرم سنة 1445 وقد أتم السابعة والستين.
            <a
              href="https://d1fx0qbs85f2yg.cloudfront.net/arabicBio.pdf"
              target="_blank"
              rel="noreferrer"
              className="bg-custom-main-light hover:bg-custom-main-dark focus:bg-custom-main-dark text-white  py-2 px-4 rounded focus:outline-none focus:shadow-outline my-10"
            >
              تحميل ترجمة الشيخ رحمه الله
            </a>
          </div>
        ) : (
          <div className="max-w-lg mx-7">
            <div className="text-md">
              <p className="text-center">
                The Biography of Sheikh Abu Mujaahid Fareed Abdullah
              </p>
              <br />
              He is the Sheikh, the Scholar, Al-Muhaddeth, Al-Faqeeh Fareed
              Yusuf Abdullah, Abu Mujaahid of the Bahamas, the resident of Los
              Angeles.
              <br />
              <br />
              He was born in Nassau, the capital of the Bahamas, on the
              twentieth of Al-Muharram, 1378. He was brought up to follow his
              parents' religion.
              <br />
              <br />
              He became a Muslim when he was in high school at about fourteen or
              fifteen years of age. He eagerly began to learn the religion of
              Islaam. A year after his conversion, he changed his birth name
              because he no longer wanted to bear the names of those who
              oppressed his forefathers.
              <br />
              <br />
              At that stage, he was driven by the enthusiasm of youth. He used
              to call his people to Islaam in public and private places, and he
              would pray wherever he was when the time for prayer came. The
              people's criticism did not affect him. His mother tried to
              dissuade him away from Islaam and sought the help of some of their
              relatives and the church. However, he remained steadfast on the
              truth.
              <br />
              <br />
              He then began his journey in seeking knowledge. He traveled to
              Guyana in South America. He stayed there for a year during which
              he learned the Arabic language and some other Islamic sciences. He
              also went to India, Pakistan, and Bangladesh.
              <br />
              <br />
              After that, he returned to his country. From there, he went to
              Saudi Arabia, where he studied at the Islamic University of Imam
              Muhammad Ibn Saud in Riyadh. There, he memorized the Book of
              Allaah, became established in various Islamic sciences, and met
              many scholars and sheikhs, including Sheikh Abdul Azeez Ibn Baaz
              and Sheikh Muhammad Ibn Saaleh Al-Uthaymeen.
              <br />
              <br />
              He studied the books of Sheikh Muhammad Nsasirul-Din Al-Albaany.
              This led him to become interested in the science of Hadeeth which
              he studied until he excelled in it and had a complete
              understanding of its branches along with the memorization of its
              texts. He used to narrate the hadeeth from his memory, hardly
              making a mistake. He was skilled in recalling ahaadeeth, citing
              them, and extracting benefits from them.
              <br />
              <br />
              His memorization of the Book of Allaah was perfect. It was easy
              for him to recall the verses when citing them, and if the reciter
              made a mistake or was confused, he was the first to remind or
              correct him.
              <br />
              <br />
              His creed was pure Salafee. He memorized the narrations of the
              companions, recalled the statements of the scholars, and resorted
              to them in understanding the texts and acting upon them.
              <br />
              <br />
              He excelled in jurisprudence, especially the jurisprudence of
              marriage, divorce, and related subjects, and the jurisprudence of
              trade and financial transactions.
              <br />
              <br />
              After graduating from the university, he went to Los Angeles in
              the year 1412. There, he settled, became the imam of Masjid
              Al-Mu'min and a teacher at Al-Madeena school, and began his Da'wah
              to Allaah. His method was to follow the Quraan and the Sunnah with
              the understanding of the righteous predecessors.
              <br />
              <br />
              He used to travel to far and near places to spread the Da'wah. He
              traveled to New York, Philadelphia, New Jersey, Kansas City,
              Salina, Riverside, Fresno, San Diego and other cities. His Da'wah
              had a great impact in all the regions he went to, and even in
              other regions that he did not even travel to. His Da'wah reached
              West Africa and Europe. He had a great impact on everyone who met
              him and everyone who learned from him.
              <br />
              <br />
              In the year 1421, he met Sheikh Muqbil Ibn Haadi Al-Waadi'ee when
              he came to California for medical treatment. He rejoiced in the
              Sheikh's arrival since he was thinking about traveling to learn
              from him. Sheikh Muqbil loved him, praised him, and attested to
              his knowledge and virtue. He used to let him address the people
              and answer their questions in his presence.
              <br />
              <br />
              Sheikh Fareed - may Allaah have mercy on him - loved the Sunnah,
              adhered to it, and was keen to follow it in every matter, small
              and large. It's as if the Sunnah had been instilled in his flesh
              and blood. You could see the effects of that on him in his
              humility, softness of speech, good manners, and kindness to
              people.
              <br />
              <br />
              He was a man of worship, and his prayer was exemplary. He used to
              pray the Duha prayer and the night prayer even while traveling.
              When Iqaama was called, he would walk among the people,
              straightening the lines. When they were straightened, he would
              advance and say takbeer to start the prayer. After praying the Eid
              prayer, he would give the Khutbah and then he would go to the
              women's side and remind them (of Allaah).
              <br />
              <br />
              He used to give his lessons calmly and had a clear, soft voice. He
              was fluent in both Arabic and English. The time used to pass so
              quickly during his lessons and lectures that those present would
              wish they would not end. This is because of the beautiful words
              and finesse of diction. It was as if he was taking the attendees
              on a ride. When Paradise was mentioned, his eyes would light up
              and he would smile and shake his head as if he could see it.
              <br />
              <br />
              He was good company both at home and outside. Conversations with
              him were beautiful. It was never dull. He used to talk to the
              people, hang out with them, eat with them, and share laughs with
              them. If a stranger entered his gathering, he would not know that
              he was the imam of the people, their sheikh, and their teacher
              because of his extreme humility. He did not like to be called:
              Sheikh, and he used to get angry at that and would stare firmly at
              the one who addressed him with it (what a stare!) and say to him:
              "Abu Mujaahid!"
              <br />
              <br />
              He was loved by everyone, young and old, since he was respectful
              of the old and merciful to the young. He greeted the children and
              played with them. And when he shook someone's hand, he would not
              be the first to withdraw his hand. He hardly complained to anyone,
              and his companions used to know that something was bothering him
              when he said: "Praise be to Allaah in every situation." He never
              asked anyone for anything of the affairs of this worldly life.
              <br />
              <br />
              All of this was easy and effortless for him because he was truly
              following the Sunnah of his Prophet -Salla Allaahu alayhi wa
              sallam-. If you sat down with him, you would learn from him things
              that you cannot find in books.
              <br />
              <br />
              He had beautiful writings, including authoring and commentary. He
              excelled at collecting and organizing evidence and conveying the
              sayings of scholars. He would add beneficial comments. He had
              invaluable footnotes in the books that he acquired.
              <br />
              <br />
              In the year 1439, he returned to his country of birth and stayed
              there, teaching and calling to Allaah, until he passed away - may
              Allaah have mercy on him - on the afternoon of Tuesday, the
              twenty-first of Al-Muharram, 1445, at sixty-seven years of age.
              <br />
              <br />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default BioPage;
