import React from "react";
import { FiShare } from "react-icons/fi";

function AudioItem({ arabic, audio, isOpen, onToggle }) {
  const handleClick = () => {
    onToggle(audio);
  };

  const handleCopyLink = async () => {
    const audioUrl = "https://d1fx0qbs85f2yg.cloudfront.net/" + audio.mediaUrl;

    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(audioUrl);
        alert("Copied Link");
      } catch (error) {
        console.error("Error copying to clipboard:", error);
        alert("Failed to copy link to clipboard.");
      }
    } else {
      // Fallback for browsers that don't support navigator.clipboard
      const tempInput = document.createElement("input");
      tempInput.value = audioUrl;
      document.body.appendChild(tempInput);
      tempInput.select();
      tempInput.setSelectionRange(0, 99999);
      document.execCommand("copy"); // Deprecated command.
      document.body.removeChild(tempInput);
      alert("Copied Link");
    }
  };

  return (
    <div className="bg-white border rounded-lg overflow-hidden shadow-md mb-4 cursor-pointer max-w-md ml-auto">
      <div className="text-right text-base font-normal p-5">
        <p onClick={handleClick} className="flex justify-between">
          <span className="italic mr-5 text-gray-400">
            {audio.date ? audio.date : ""}
          </span>
          {arabic ? audio.arabicTitle : audio.englishTitle}
        </p>
        <div
          className="transition-all duration-300"
          style={{
            maxHeight: isOpen ? "100px" : "0",
            opacity: isOpen ? 1 : 0,
            overflow: isOpen ? "visible" : "hidden",
          }}
        >
          {isOpen && (
            <div className="flex justify-between items-center w-full">
              <button onClick={handleCopyLink} className="mr-3">
                <FiShare size={20} />
              </button>
              <audio controls controlsList="nodownload" preload="metadata">
                <source
                  src={
                    "https://d1fx0qbs85f2yg.cloudfront.net/" + audio.mediaUrl
                  }
                  preload="none"
                />
                Your browser does not support the audio element.
              </audio>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AudioItem;
