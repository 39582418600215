import React from "react";

function HomePage({ arabic }) {
  return (
    <>
      <h1 className="text-black font-bold text-right mr-7">
        {arabic ? "الرئيسة" : "Home"}
      </h1>
      <div className="flex items-center justify-center my-10">
        {arabic ? (
          <div
            className="max-w-lg mx-7 flex flex-col items-center justify-center text-lg  text-center"
            dir="rtl"
            lang="ar"
          >
            إن الحمد لله، نحمده ونستعينه ونستغفره، ونعوذ بالله من شرور أنفسنا
            ومن سيئات أعمالنا، من يهده الله فلا مضل له، ومن يضلل فلا هادي له،
            وأشهد أن لا إله إلا الله وحده لا شريك له، وأشهد أن محمدًا عبده
            ورسوله.
            <br />
            <br />
            ﴿يَا أَيُّهَا الَّذِينَ آمَنُوا اتَّقُوا اللَّهَ حَقَّ تُقَاتِهِ
            وَلا تَمُوتُنَّ إِلَّا وَأَنْتُمْ مُسْلِمُونَ﴾ . ﴿يَا أَيُّهَا
            النَّاسُ اتَّقُوا رَبَّكُمُ الَّذِي خَلَقَكُمْ مِنْ نَفْسٍ وَاحِدَةٍ
            وَخَلَقَ مِنْهَا زَوْجَهَا وَبَثَّ مِنْهُمَا رِجَالًا كَثِيرًا
            وَنِسَاءً وَاتَّقُوا اللَّهَ الَّذِي تَسَاءَلُونَ بِهِ
            وَالْأَرْحَامَ إِنَّ اللَّهَ كَانَ عَلَيْكُمْ رَقِيبًا﴾ ﴿يَا
            أَيُّهَا الَّذِينَ آمَنُوا اتَّقُوا اللَّهَ وَقُولُوا قَوْلًا
            سَدِيدًا، يُصْلِحْ لَكُمْ أَعْمَالَكُمْ وَيَغْفِرْ لَكُمْ
            ذُنُوبَكُمْ وَمَنْ يُطِعِ اللَّهَ وَرَسُولَهُ فَقَدْ فَازَ فَوْزًا
            عَظِيمًا﴾
            <br />
            <br />
            أما بعد، فإن هذا الموقع هو نتيجة تعاون بين أصحاب وطلاب الشيخ فريد
            عبد الله - رحمه الله تعالى - في مسجد المؤمن / لوس أنجلوس ومسجد أهل
            السنة / جزر البهاما.
            <br />
            <br />
            ويهدف هذا الموقع إلى نشر علم الشيخ - رحمه الله - والتعريف به. نسأل
            الله أن يتقبل منا هذا العمل وأن يكون في ميزان حسنات شيخنا - رحمه
            الله تعالى -
            <br />
            <br />
          </div>
        ) : (
          <div className="max-w-lg mx-7">
            <div className="text-md text-left">
              Surely, all praise is due to Allaah, we praise Him and we seek His
              help and forgiveness. And we seek refuge with Allaah from the
              evils of our souls and from our sinful acts. Whoever Allaah
              guides, no one can misguide and whoever Allaah misguides, no one
              can guide. And I testify that there is no true God worthy of
              worship except Allaah, and I testify that Mohammad is His servant
              and messenger.
              <br />
              <br />
              <div className="m-0" dir="rtl" lang="ar">
                ﴿يَا أَيُّهَا الَّذِينَ آمَنُوا اتَّقُوا اللَّهَ حَقَّ تُقَاتِهِ
                وَلا تَمُوتُنَّ إِلَّا وَأَنْتُمْ مُسْلِمُونَ﴾ . ﴿يَا أَيُّهَا
                النَّاسُ اتَّقُوا رَبَّكُمُ الَّذِي خَلَقَكُمْ مِنْ نَفْسٍ
                وَاحِدَةٍ وَخَلَقَ مِنْهَا زَوْجَهَا وَبَثَّ مِنْهُمَا رِجَالًا
                كَثِيرًا وَنِسَاءً وَاتَّقُوا اللَّهَ الَّذِي تَسَاءَلُونَ بِهِ
                وَالْأَرْحَامَ إِنَّ اللَّهَ كَانَ عَلَيْكُمْ رَقِيبًا﴾ ﴿يَا
                أَيُّهَا الَّذِينَ آمَنُوا اتَّقُوا اللَّهَ وَقُولُوا قَوْلًا
                سَدِيدًا، يُصْلِحْ لَكُمْ أَعْمَالَكُمْ وَيَغْفِرْ لَكُمْ
                ذُنُوبَكُمْ وَمَنْ يُطِعِ اللَّهَ وَرَسُولَهُ فَقَدْ فَازَ
                فَوْزًا عَظِيمًا﴾
              </div>
              <br />
              And after that, this website is a collaboration among the friends
              and students of Sheikh Fareed Abdullah -Rahimahullaah- in Masjid
              Al Mumin in Los Angeles and Masjid Ahlus Sunnah in the Bahamas.
              <br />
              <br />
              The main purpose of this website is to introduce the Sheikh
              -Rahimahullaah- and spread his knowledge.
              <br />
              <br />
              We ask Allaah to accept this deed from us and to make it a source
              of continuous reward for our beloved Sheikh - Rahimahullaah-.
              <br />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default HomePage;
