import React, { useState } from "react";
import { TopicSelector, SearchBar, AudioList } from "../Components";

function AudioPage({ arabic, audioLecturesList }) {
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  // Filter audio list based on the selected topic and search query
  const filteredAudioList = audioLecturesList
    .filter((audio) => {
      if (selectedTopic) {
        return (
          audio.englishTopic === selectedTopic.englishTopic ||
          audio.arabicTopic === selectedTopic.arabicTopic
        );
      }
      return true;
    })
    .filter(
      (audio) =>
        audio.arabicTitle.toLowerCase().includes(searchQuery.toLowerCase()) ||
        audio.englishTitle.toLowerCase().includes(searchQuery.toLowerCase())
    );

  return (
    <div className="ml-1 mr-1 md:mr-7">
      <h1 className="text-black font-bold text-right mb-5">
        {arabic ? "الصوتيات" : "Audio Lectures"}
      </h1>
      <SearchBar
        arabic={arabic}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        setCurrentPage={setCurrentPage}
      />
      <TopicSelector arabic={arabic} setSelectedTopic={setSelectedTopic} />
      <AudioList
        arabic={arabic}
        filteredAudioList={filteredAudioList}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}

export default AudioPage;
