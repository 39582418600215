import React, { useState } from "react";
import { AudioList, SearchBar } from "../Components";

function KhutbahsPage({ arabic, khutbahsList }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  // Filter audio list based on the selected topic and search query
  const filteredKhutbahList = khutbahsList.filter(
    (audio) =>
      audio.arabicTitle.toLowerCase().includes(searchQuery.toLowerCase()) ||
      audio.englishTitle.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="ml-1 mr-1 md:mr-7">
      <h1 className="text-black font-bold text-right mb-5">
        {arabic ? "خطب الجمعة" : "Jumuah Khutbahs"}
      </h1>
      <SearchBar
        arabic={arabic}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      <AudioList
        arabic={arabic}
        filteredAudioList={filteredKhutbahList}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}

export default KhutbahsPage;
