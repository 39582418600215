import React, { useState, useEffect } from "react";

function TopicSelector({ arabic, setSelectedTopic }) {
  const [topics, setTopics] = useState([]);

  const getTopicList = async () => {
    try {
      const response = await fetch(
        "https://d1fx0qbs85f2yg.cloudfront.net/topicDB.json"
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const topicListData = await response.json();
      setTopics(topicListData);
      console.log(topicListData);
      return topicListData;
    } catch (error) {
      console.error("Error fetching topic list:", error);
      throw error;
    }
  };

  useEffect(() => {
    getTopicList();
  }, []);

  return (
    <div className="max-w-md ml-auto mb-5">
      <div>
        <label className="text-sm font-semibold" htmlFor="topicSelect"></label>
        <select
          id="topicSelect"
          className="p-2 border rounded w-full"
          onChange={(e) => {
            setSelectedTopic(e.target.value ? JSON.parse(e.target.value) : "");
          }}
        >
          <option value={""}>
            {arabic ? "تصفية حسب الموضوع" : "Filter by Topic"}
          </option>
          {topics.map((topic, index) => (
            <option key={index} value={JSON.stringify(topic)}>
              {arabic ? topic.arabicTopic : topic.englishTopic}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default TopicSelector;
