import React, { useState, useEffect } from "react";
import { AudioItem, Pagination } from "./";

function AudioList({ arabic, filteredAudioList }) {
  const [openedAudio, setOpenedAudio] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  // Handle opening/closing an audio item
  const handleToggleAudio = (audio) => {
    if (openedAudio === audio) {
      setOpenedAudio(null);
    } else {
      setOpenedAudio(audio);
    }
  };

  // Handle page navigation
  const goToPage = (page) => {
    setCurrentPage(page);
  };

  // Calculate the start and end indexes of the current page
  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Update slice of currentAudioList
  const currentAudioList = filteredAudioList.slice(startIndex, endIndex);

  // Reset current page when filtered audio list changes
  useEffect(() => {
    setCurrentPage(1);
  }, [filteredAudioList]);

  return (
    <div>
      {currentAudioList.length === 0 ? (
        <p className="max-w-md ml-auto mb-5 text-right text-gray-600">
          {arabic ? ".لم يتم العثور على نتائج" : "No results found."}
        </p>
      ) : (
        <>
          {currentAudioList.map((audio) => (
            <AudioItem
              key={audio.id}
              arabic={arabic}
              audio={audio}
              isOpen={openedAudio === audio}
              onToggle={handleToggleAudio}
            />
          ))}
          <Pagination
            currentPage={currentPage}
            totalItems={filteredAudioList.length}
            itemsPerPage={itemsPerPage}
            goToPage={goToPage}
          />
        </>
      )}
    </div>
  );
}

export default AudioList;
